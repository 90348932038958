import React from "react";

import {
    Container,
    ContentCustomContainer3,
    Cards,
    SubContainerContent4,
    TableCard
} from "../styles/projects";

import {
    MainContainerStyle
} from "../globalStyles";

import Image0 from "../images/Projects/Project.jpg";
import Image1 from "../images/Projects/BookAndPC.jpg";

class Projects extends React.Component{
    render(){
        return(
            <>
                <Container>
                    <MainContainerStyle 
                    BackgroundImage={Image0}
                    ImgWidth="60%"
                    ArticleWidth="40%">
                        <article
                            data-sal="slide-right"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <title>
                                Projetos
                            </title>
                            Aqui você pode ver meus projetos já realizados e futuros projetos. 
                            <br/>
                            Também, a esturra, e diferentes componentes e partes do meu site.
                        </article>
                        <div
                            data-sal="slide-left"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                        </div>
                    </MainContainerStyle>
                    <MainContainerStyle 
                    BackgroundImage={Image1}>
                        <div
                            data-sal="slide-right"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                        </div>
                        <article
                            data-sal="slide-left"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                            <title>
                                Tecnologias que uso
                            </title>
                            Conheço e para a maioria dos projetos atualmente uso: <br/>
                            <ul>
                                <li>ReactJS / GatsbyJS / NextJS </li>
                                <li>Node.JS / Deno</li>
                                <li>Typescript / Javascript</li>
                                <li>ExpressJS / AdonisJs</li>
                                <li>MongoDB / MySQL / Redis</li>
                                <li>Kubernetes / Docker</li>
                            </ul><br/>
                            Porem, também conheço outras, da qual não faço muito uso: <br/>
                            <ul>
                                <li>C# (primeira linguagem que aprendi)</li>
                                <li>React Native </li>
                                <li>PHP</li>
                                <li>Python</li>
                            </ul>
                        </article>
                    </MainContainerStyle>
                    <ContentCustomContainer3>
                        <title>
                            Arquitetura desse site
                        </title>
                        <sub>
                            <Cards
                            data-sal="slide-up"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                                <title>
                                    Frontend principal V1.1:
                                </title>
                                <article>
                                    Esta parte visual que você está visitando agora. (Atualmente a segunda versão está sendo desenvolvida...)
                                    <br/><br/>
                                    Usa: GatsbyJS
                                </article>
                            </Cards>
                            <Cards
                            data-sal="slide-up"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                                <title>
                                    Frontend administrador V0.3-beta:
                                </title>
                                <article>
                                    Esta é a parte visual que somente acessível por administrador. (Ainda não está no ar)
                                    <br/><br/>
                                    Usa: NextJS
                                </article>
                            </Cards>
                            <Cards
                            data-sal="slide-up"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                                <title>
                                    Backend de administração V1.0:
                                </title>
                                <article>
                                    Parte não visual, responsável por: principais regras de negocio, autenticação armazenamento de mensagens de contato, ... 
                                    <br/><br/>
                                    Usa: Node.js + ExpressJS (Atualização do serviço planejada usando AdonisJS e MySQL de preferencia)
                                </article>
                            </Cards>    
                        </sub>
                    </ContentCustomContainer3>
                    <SubContainerContent4>
                        <sub>
                            <title>
                                Projetos realizados:
                            </title>
                            <TableCard
                            data-sal="slide-up"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Tecnologias</th>
                                            <th>Descrição</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <a href="https://tpe-2019.victorgomez.dev/">Jardim IOT</a>
                                            </th>
                                            <th>PHP + C + Arduino + HTML/Bootstrap + JS</th>
                                            <th>Jardim IOT totalmente automático para um projeto de escola</th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <a href="https://github.com/Vicg853/Zhe-Pasteur-News">Zhe Pasteur News (Project's github link)(<span style={{color: 'red'}}>OFFLINE</span>)</a>
                                            </th>
                                            <th>PHP + JS + HTML/Bootstrap</th>
                                            <th>Jornal escolar online. ()</th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <a href="https://github.com/Vicg853/ElectionsCVL2020">CVL Elections (Project's github link)</a>
                                            </th>
                                            <th>GatsbyJS + ExpressJs + MongoDB + K8s + Docker</th>
                                            <th>Site para votação online de candidatos para o conselho de estudantes</th>
                                        </tr>
                                        <tr>
                                            <th>
                                                <a href="https://github.com/Vicg853/Old_portfolio">Portfolio V0.1-beta (Project's github link)</a>
                                            </th>
                                            <th>HTML5/CSS3/JS</th>  
                                            <th>Versão beta do portfolio que nunca entrou no ar </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </TableCard>
                        </sub>
                        <sub>
                            <title>
                                Projetos futuros ou em desenvolvimento ou gerenciados ativamente:
                            </title>
                            <TableCard
                            data-sal="slide-up"
                            data-sal-delay="200"
                            data-sal-easing="ease">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Tecnologias</th>
                                            <th>Descrição</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th><a href="https://projects.victorgomez.dev/">PacmanISN (Project's webpage)</a></th>
                                            <th>HTML5/CSS3/JS + ThreeJS lib</th>
                                            <th>Uma mini versão 3D do jogo Pacman, iniciado para um projeto escolar. (Github: https://github.com/Vicg853/Pacman_isn)</th>
                                        </tr>
                                        <tr>
                                            <th><a href="https://deno.land/x/dyarn@v1.1.0">Dyarn</a></th>
                                            <th>Typescript + Deno</th>
                                            <th>Runner helper para nosso amigável/querido dinossauro Deno, evitando então de usar longos comandos constantemente ao desenvolver/compilar/rodar app com Deno.</th>
                                        </tr> 
                                        <tr>
                                            <th><a href="https://deno.land/x/dyarn@v1.1.0">Disq Code Bot</a></th>
                                            <th>Typescript + Deno + Harmony (Deno Lib)</th>
                                            <th>O bot que te ajuda com documentação, packages e coisas relacionadas a desenvolvimento (mais features por vir!). (Github: https://github.com/Disq-Code-Bot) (<a href="https://discord.com/api/oauth2/authorize?client_id=921609059487060000&permissions=446744226880&scope=bot">Add to Discord</a>)</th>
                                        </tr> 
                                        <tr>
                                            <th><a href="https://alpescap-canary.victorgomez.dev/">AlpesCap Webpage v2</a></th>
                                            <th>NextJS/React</th>
                                            <th>Landing page da Alpes capital (site atual feito com wix: https://www.alpescap.com.br/), que se trata de uma ONG/Projeto do qual participo (em marketing/dev). (Github: https://github.com/Alpes-Capital/landing-page-v2)</th>
					                    </tr>
                                    </tbody>
                                </table>
                            </TableCard>
                        </sub>
                    </SubContainerContent4>
                </Container> 
            </>
        );
    }
}

export default Projects;
