import styled from "styled-components";

import {
    Theme,
    RootContainerStyle
} from "../globalStyles";

export const Container = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentCustomContainer3 = styled(RootContainerStyle)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
    title{
        display: block;
        font-size: 20px;
        font-weight: bold;
        color: ${Theme.accent1};
        ::after{
            display: block;
            content: "";
            width: 70px;
            height: 3px;
            background-color: ${Theme.accent3};
            margin-top: 10px;
        }
    }
    sub{
        display: inline-flex;
        justify-content: space-around;
        margin-top: 25px;
        width: 100%;
    }
    @media screen and (max-width: 1200px){
        width: calc(100vw - 50px);
    }
    @media screen and (max-width: 1050px){
        sub{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }
`;

export const Cards = styled.div`
    width: 210px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 15px;
    background-color: ${Theme.accent2};
    border-radius: ${Theme.borderRadius};
    padding: 1rem;
    title{
        width: 100%;
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: ${Theme.accent3};
        ::after{
            display: none;
        }
    }
    article{
        width: 100%;
        display: block;
        margin-top: 20px;
        font-size: 15.5px;
        font-weight: normal;
        color: ${Theme.accent1};
        text-justify: auto;
    }
    @media screen and (max-width: 530px){
        margin: 5px;
        margin-top: 20px;
    }
    @media screen and (max-width: 490px){
        width: 100%;
        height: auto;
        margin: 0px;
        margin-top: 20px;
    }
`;

export const SubContainerContent4 = styled(RootContainerStyle)`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    title{
        display: block;
        font-size: 20px;
        font-weight: bold;
        color: ${Theme.accent1};
        ::after{
            display: block;
            content: "";
            width: 70px;
            height: 3px;
            background-color: ${Theme.accent3};
            margin-top: 10px;
            margin-bottom: 17px;
        }
    }
    sub{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;
    }
`;

export const TableCard = styled.div`
    width: calc(100% - 15px);
    min-height: 200px;
    padding: 0.1rem;
    display: flex;
    flex-direction: column;
    background-color: ${Theme.accent2};
    border-radius: ${Theme.borderRadius};
    align-items: center;
    table{
        width: 98%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        thead, tbody{
            width: 100%;
        }
        thead{
            display: block;
            color: ${Theme.accent1};
            th{
                font-weight: bold;
                font-size: 18px;
            }
        }
        tbody{
            display: block;
            color: ${Theme.accent1};
            th{
                font-weight: 400;
                opacity: 0.7;
                font-size: 15.5px;
            }
        }
        tr{
            display: inline-flex;
            width: 100%;
            border-bottom: 2px solid ${Theme.accent3};
            text-align: left;
            padding-left: 7px;
            padding-right: 7px;
            padding-top: 10px;
            padding-bottom: 10px;
            th{
                :nth-child(1){
                    display: block;
                    width: 220px;
                    margin-right: 5px;
                }
                :nth-child(2){
                    display: block;
                    width: 220px;
                    margin-left: 5px;
                    margin-right: 5px;
                }
                :nth-child(3){
                    display: block;
                    width: calc(100% - 440px);
                    margin-left: 5px;
                }
                a{
                    width: fit-content;
                    text-decoration-color: ${Theme.accent3};
                    text-decoration-line: none;
                    color: ${Theme.accent3};
                    display: block;
                    overflow: hidden;
                    align-items: center;
                    ::after{
                        display: block;
                        content: "";
                        width: 0px;
                        height: 3px;
                        background-color: ${Theme.accent3};
                        margin-top: -3px;
                        transition: 0.3s;
                    }
                    :hover{
                        ::after{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 720px){
        width: 100%;
        margin: 0px;
        overflow-x: scroll;
        align-items: flex-start;
        table{ 
            width: 900px;
            margin-left: 20px;
            margin-right: 20px;
        }
        ::-webkit-scrollbar{
            height: 3.5px;
            background-color: transparent;
            color: transparent;
            padding-bottom: 2px;
            padding-left: 2px;
            padding-right: 2px;
            border-radius: ${Theme.borderRadius};
        }
        ::-webkit-scrollbar-thumb{
            background-color: #FFFFFF;
            color: #FFFFFF;
            width: 14px;
        }
        ::-webkit-scrollbar-track{
            background-color: #19191E;
            color: #19191E;
        }
    }
`;
